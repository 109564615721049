require('./bootstrap');

$(window).on('load', function () {
  $('body').removeClass('loading-page');
  $('#loader').remove();
});

$(document).ready(function () {
  /* globals */
  let doCount = true;

  $('.submenu .item').click(function () {
    var url = window.location.origin + '/catalogo/' + $(this).attr('item-id');
    window.location = url;
  });

  $('#categories .gallery .item').click(function () {
    var url = window.location.origin + '/catalogo/' + $(this).attr('item-id');
    window.location = url;
  });

  /* ### NUMBERS COUNTER AT HOME PAGE ### */
  $(window).scroll(function () {
    var $this = $(this);
    var screen = $this.scrollTop() + $this.height();
    var counter = $('.numbers').height();

    if (screen > counter && doCount) {
      $('.count span').each(function () {
        var $this = $(this),
          countTo = $this.text();
        $({ countNum: 0 }).animate({
          countNum: countTo
        },
          {
            duration: 3000,
            easing: 'linear',
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
              //alert('finished');
            }

          });
      });
      doCount = false;
    }
  });
  /* ### END NUMBERS COUNTER AT HOME PAGE ### */

  $('#products .gallery .item').click(function () {
    /* CLEAR CONTENT */
    $('#product-details .modal-body .left .banner-product').attr('src', '');
    $('#product-details .modal-body .right .content .parent').text('');
    $('#product-details .modal-body .right .content .title').text('');
    $('#product-details .modal-body .right .content .description').text('');
    $('#product-details .modal-body .right .content .product-information').text('');
    $('#product-details .modal-body .right .content .base').text('');
    $('#product-details .modal-body .right .content .include-box').remove();
    /* END CLEAR CONTENT */

    var id = $(this).attr('item-id');
    var parent = $(this).attr('parent');
    var url = window.location.origin + "/products/details/" + id;

    $.ajax({
      url: url, success: function (data) {
        var addContent = '';
        $('#product-details .modal-body .left .banner-product').attr('src', data.banner);
        $('#product-details .modal-body .right .content .parent').append(parent);
        $('#product-details .modal-body .right .content .title').append(data.name);
        $('#product-details .modal-body .right .content .base').addClass('hidden');
        /* $('#product-details .modal-body .right .content .description').append(data.description); */

        /* Add ingredient base and icon if exist */
        if (data.base.length) {
          if (data.base_icon.length) {
            addContent += '<div class="icon"><div class="' + data.base_icon + '"></div></div>';
          }
          addContent += '<div class="text-base">' + data.base + '</div>';
          $('#product-details .modal-body .right .content .base').append(addContent);
          $('#product-details .modal-body .right .content .base').removeClass('hidden');
          addContent = '';
        }

        /* Add ingredients information */
        for (let i = 0; i < data.ingredients.length; i++) {
          addContent += '<div class="information">';
          /* Line infrmation */
          if (data.ingredients[i].line_name.length) {
            addContent += '<div class="line ';
            addContent += data.ingredients[i].line_color.length ? data.ingredients[i].line_color : '';
            addContent += '">' + data.ingredients[i].line_name + '</div>';
          }
          /* Ingredients information */
          if (data.ingredients[i].ingredient_description.length || data.ingredients[i].base_description.length) {
            addContent += '<div class="subtitle">Ingredientes:</div>';
            if (data.ingredients[i].ingredient_description.length) {
              addContent += '<div class="description">' + data.ingredients[i].ingredient_description + '</div>';
            }
            if (data.ingredients[i].base_description.length) {
              addContent += '<div class="description"><b>Base: </b>' + data.ingredients[i].base_description + '</div>';
            }
          }
          /* Formula Information */
          if (data.ingredients[i].formula.length) {
            addContent += '<div class="formula ';
            addContent += data.ingredients[i].line_color.length ? data.ingredients[i].line_color : '';
            addContent += '">' + data.ingredients[i].formula + '</div>';
          }
          /* Append content */
          addContent += '</div>';
        }
        $('#product-details .modal-body .right .content .product-information').append(addContent);

        addContent = '';

        if (parent == 'estañados') {
          addContent = '<div class="include-box"> <div class="left-box"> <div class="kind">Tipo:</div><div class="qty"><span>•</span> 15 litros</div></div><div class="line-box"></div><div class="right-box"> <p class="white">Incluye caja</p><p class="blue">para producto</p></div></div>';
          $('#product-details .modal-body .right .content .product-information').before(addContent);
          addContent = '';
        }

        $('#product-details').modal('show');
      }
    });
  });

  $('.submenu-catalogue').click(function () {
    if ($(this).children('.submenu').hasClass('active')) {
      $(this).children('.submenu').removeClass('active');
    } else {
      $(this).children('.submenu').addClass('active');
    }
  });

  $('.search').click(function () {
    $('#search').modal('show');
  });

  $('.search-mobile').on('click', function () {
    $('#menu-mobile').modal('hide');
  });

  $('#searchbar').keyup(function () {
    if ($(this).val().length > 0) {
      var search = $(this).val();
      $.get(
        '/products/search',
        {
          search: search
        },
        function (res) {
          console.log(res);
          $('#results').html('');
          for (let i = 0; i < res.length; i++) {
            const item = res[i];
            $('#results').append(
              '<div class="item-result" '
              + 'item-id="' + item.id + '" '
              + 'parent="">'
              + item.name
              + '</div>'
            );
          }

          $('#results .item-result').click(function () {
            console.log('item', $(this));

            // CLEAR CONTENT
            $('#product-details .modal-body .left .banner-product').attr('src', '');
            $('#product-details .modal-body .right .content .parent').text('');
            $('#product-details .modal-body .right .content .title').text('');
            $('#product-details .modal-body .right .content .description').text('');
            $('#product-details .modal-body .right .content .product-information').text('');
            $('#product-details .modal-body .right .content .base').text('');
            $('#product-details .modal-body .right .content .include-box').remove();
            // END CLEAR CONTENT
        
            var id = $(this).attr('item-id');
            var parent = $(this).attr('parent');
            var url = window.location.origin + "/products/details/" + id;

            $.ajax({
              url: url, success: function (data) {
                var addContent = '';
                $('#product-details .modal-body .left .banner-product').attr('src', data.banner);
                $('#product-details .modal-body .right .content .parent').append(parent);
                $('#product-details .modal-body .right .content .title').append(data.name);
                $('#product-details .modal-body .right .content .base').addClass('hidden');
        
                if (data.base.length) {
                  if (data.base_icon.length) {
                    addContent += '<div class="icon"><div class="' + data.base_icon + '"></div></div>';
                  }
                  addContent += '<div class="text-base">' + data.base + '</div>';
                  $('#product-details .modal-body .right .content .base').append(addContent);
                  $('#product-details .modal-body .right .content .base').removeClass('hidden');
                  addContent = '';
                }
        
        
                for (let i = 0; i < data.ingredients.length; i++) {
                  addContent += '<div class="information">';
        
                  if (data.ingredients[i].line_name.length) {
                    addContent += '<div class="line ';
                    addContent += data.ingredients[i].line_color.length ? data.ingredients[i].line_color : '';
                    addContent += '">' + data.ingredients[i].line_name + '</div>';
                  }
        
                  if (data.ingredients[i].ingredient_description.length || data.ingredients[i].base_description.length) {
                    addContent += '<div class="subtitle">Ingredientes:</div>';
                    if (data.ingredients[i].ingredient_description.length) {
                      addContent += '<div class="description">' + data.ingredients[i].ingredient_description + '</div>';
                    }
                    if (data.ingredients[i].base_description.length) {
                      addContent += '<div class="description"><b>Base: </b>' + data.ingredients[i].base_description + '</div>';
                    }
                  }
        
                  if (data.ingredients[i].formula.length) {
                    addContent += '<div class="formula ';
                    addContent += data.ingredients[i].line_color.length ? data.ingredients[i].line_color : '';
                    addContent += '">' + data.ingredients[i].formula + '</div>';
                  }
        
                  addContent += '</div>';
                }
                $('#product-details .modal-body .right .content .product-information').append(addContent);
        
                addContent = '';
        
                if (parent == 'estañados') {
                  addContent = '<div class="include-box"> <div class="left-box"> <div class="kind">Tipo:</div><div class="qty"><span>•</span> 15 litros</div></div><div class="line-box"></div><div class="right-box"> <p class="white">Incluye caja</p><p class="blue">para producto</p></div></div>';
                  $('#product-details .modal-body .right .content .product-information').before(addContent);
                  addContent = '';
                }
        
                $('#product-details').modal('show');
              }
            });
          });
        });
      return;
    }
    $('#results').empty();
  });
});